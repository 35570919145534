import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckBox,
  SecondaryButton,
  DialogBox
} from '../../../msreact-kit/packages/html-components';
import idConstants from '../../../data/idConstants';
import {
  sellerTenantRequest,
  getProductCategories
} from '../../../actions/api/CategoryMappingAction';
import { TENANT } from '../../../actions/configuration';
import { getBuyerList } from '../../../actions/UserService';
import { getTenantList } from '../../../actions/api/publishRequestAction';
import images from '../../../assets/img/';
import {
  getPinDetails,
  createPin,
  updatePin
} from '../../../actions/api/SellerTenantPinAction';
import { Dropdown } from '../../../msreact-kit/packages/html-components';

import './index.scss';

const Card = ({
  list,
  tenantCategoryMapping,
  checked,
  setChecked,
  basicInfo,
  enabledTenants,
  disabledTenants,
  pins,
  setPins,
  setSelectedBuyer
}) => {
  const dispatch = useDispatch();
  const [showPopUp, setShowPopUp] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [selectedBuyerData, setSelectedBuyerData] = useState([]);
  const [buyerList, setBuyerList] = useState();
  const isMobilityLabs = enabledTenants?.some(
    (data) => data && data.tenantId === TENANT.MOBILITYLABS
  );

  const { productCategories } = useSelector((state) => {
    return {
      productCategories: state.CategoryMappingReducer.productCategories
    };
  });

  useEffect(() => {
    dispatch(getProductCategories());
    dispatch(getPinDetails()).then((res) => {
      setPins(res);
    });
    if (isMobilityLabs) {
      dispatch(getBuyerList()).then((res) => {
        setBuyerList(res);
      });
    }
  }, []);

  const filterCategory = tenantCategoryMapping?.filter((item) => {
    if (list?.tenantId === item?.tenantInfo?.tenantId) {
      return item;
    }
  });

  const payload = {
    tenantId: list?.tenantId
  };

  const handlePinClick = () => {
    if (!pins.length) {
      dispatch(createPin(payload)).then((res) => {
        if (res) {
          dispatch(getPinDetails()).then((response) => {
            setPins(response);
          });
        }
      });
    } else {
      dispatch(updatePin(payload)).then((res) => {
        if (res) {
          dispatch(getPinDetails()).then((response) => {
            setPins(response);
          });
        }
      });
    }
  };

  const handleEnabledCategoryIds = (name) => {
    const matchingCategory = productCategories?.find(
      (category) => category?.categoryId === name
    );
    return matchingCategory?.displayName;
  };

  const productCategoryId =
    basicInfo?.productCategory?.categoryId ===
    idConstants?.automotiveSpares?.categoryId
      ? basicInfo?.productCategory?.categoryId
      : basicInfo?.productCategoryId;

  const handleClick = () => {
    dispatch(sellerTenantRequest(list?.tenantName, productCategoryId)).then(
      (res) => {
        if (res) {
          setDialogContent({
            dialogTitle: 'TENANT_SELECTION.INTEREST_SENT',
            dialogState: true
          });
          setShowPopUp(true);
        }
        dispatch(getTenantList(basicInfo?.productId));
      }
    );
  };

  const handleCheckboxChange = (name) => {
    setChecked((prevState) => {
      if (prevState.includes(name)) {
        return prevState.filter((item) => item !== name);
      } else {
        return [...prevState, name];
      }
    });
  };

  const handleCancel = () => {
    setDialogContent({});
    setShowPopUp(false);
  };

  let displayName = list?.displayName;
  const words = displayName?.split(' ');
  if (words?.length >= 2) {
    displayName = words[0] + words.slice(2).join(' ');
  }

  const handleSelectedBuyer = (event) => {
    setSelectedBuyerData(event.value);
  };

  useEffect(() => {
    if (isMobilityLabs) {
      setSelectedBuyer(selectedBuyerData?.id);
    }
  }, [selectedBuyerData]);

  return (
    <React.Fragment>
      <div
        className={`card-box ${
          pins[0]?.tenantId === list?.tenantId ? 'enabled-card' : ''
        } ${disabledTenants || pins ? 'disabled-card' : ''}  ${
          checked?.includes(list?.tenantName) ? 'enabled-card' : ''
        }`}
      >
        <div className='card-box__container'>
          <div className='card-box__container__image-icon'>
            <p className='card-box__container__image-icon__text'>{displayName}</p>
          </div>
          <div className='card-box__container__link-container'>
            <div className='card-box__container__link-container__heading'>
              <div className='card-box__container__link-container__heading__link'>
                {`${list?.displayName}`}
                {`${list?.displayName}` === `${i18next.t('PS_IN.PS_IN_WEBSHOP')}`
                  ? ''
                  : ` ${i18next.t('AUTOMOTIVE.MARKETPLACE')}`}
              </div>
              <div className='card-box__container__link-container__heading__img'>
                <a href={list?.siteLink} target='_blank' rel='noreferrer'>
                  <img
                    src={images.externallink}
                    alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.EXTERNAL_LINK')}
                  />
                </a>
              </div>
            </div>
            <p>{i18next.t('TENANT_SELECTION.CATEGORIES_OFFERED')}</p>
            <div className='card-box__container__link-container__category-container'>
              {filterCategory[0]?.enabledCategoryIds?.map((item) => (
                <div
                  className='card-box__container__link-container__category-container__category'
                  key={item}
                >
                  <p className='card-box__container__link-container__category-container__category__name'>
                    {handleEnabledCategoryIds(item)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {isMobilityLabs && (
          <div className='card-box__single-select'>
            <Dropdown
              displayName={i18next.t('SHARED.SELECT_BUYERS')}
              value={selectedBuyerData}
              handleChange={(event) => {
                handleSelectedBuyer(event);
              }}
              name='selectOptions'
              options={buyerList?.map((data) => ({
                label: data.buyerCompanyName,
                id: data.buyerUserId,
                value: data.buyerCompanyName
              }))}
              placeHolder={i18next.t('DYNAMIC_FORM.API_STATISTICS.SELECT_OPTIONS')}
            />
          </div>
        )}
        <div className='card-box__tenant'>
          {enabledTenants && (
            <div className='card-box__pin'>
              <img
                src={images.pin}
                className={`card-box__pin__img ${
                  pins[0]?.tenantId === list?.tenantId ? 'enabled' : ''
                }`}
                onClick={handlePinClick}
              />
            </div>
          )}
          <div className='card-box__box'>
            {list?.isEnabled === false ? (
              <SecondaryButton
                label={
                  list?.isRequested === true
                    ? i18next.t('TENANT_SELECTION.REQUEST_SENT')
                    : i18next.t('TENANT_SELECTION.SEND_INTEREST')
                }
                onClick={handleClick}
                disabled={list?.isRequested === true}
              />
            ) : (
              <CheckBox
                displayName={i18next.t('DYNAMIC_FORM.LABEL.PUBLISH')}
                id={list?.tenantId}
                name={list?.tenantName}
                handleChange={(data) => handleCheckboxChange(data.name)}
                checked={checked[list?.name]}
                className={'check'}
                disabled={list?.published === true}
              />
            )}
          </div>
        </div>
        {showPopUp && (
          <DialogBox
            title={i18next.t(dialogContent?.dialogTitle)}
            subTitle={''}
            isSuccess={dialogContent?.dialogState}
            isModal={true}
            isSecondaryBtn={false}
            handleConfirm={handleCancel}
            primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.OKAY')}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Card;
