import React, { useState, useEffect } from 'react';
import Loader from '../../Shared/Utils/Loader';
import GeneratePDF from './ExportToPDF';
import GenerateExcel from './ExportToExcel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MetricsGraph from './GraphMetrics';
import isEmpty from '../../../validator/isEmpty';
import {
  Table,
  Dropdown,
  MultiSelect
} from '../../../msreact-kit/packages/html-components';
import './index.scss';
import i18next from 'i18next';
import APIHitsBreakDown from './APIHitsBreakDown';
import Footer from '../../BaseLayout/footer';
import idConstants from '../../../data/idConstants';
import { Box, FormControl, FormHelperText } from '@mui/material';

const apiStatisticsHeadings = [
  {
    id: '1',
    value: 'Api Name',
    key: 'apiName'
  },
  {
    id: '2',
    value: 'Total API Requests',
    key: 'totalRequest'
  },
  {
    id: '3',
    value: 'Chargeable Requests',
    key: 'chargeableHits',
    style: 'response-code-color',
    click: true
  },
  {
    id: '4',
    value: 'Non-chargeable Requests',
    key: 'nonChargeableHits',
    style: 'response-code-color',
    click: true
  },
  {
    id: '5',
    value: 'Blocked Requests',
    key: 'blockedRequest'
  },
  {
    id: '6',
    value: 'Average Latency',
    key: 'averageLatency'
  },
  {
    id: '7',
    value: 'Total Active Subscriptions',
    key: 'subscribedUsers'
  }
];

const ApiStatistics = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showText, setShowText] = useState(false);
  const [showFromDate, setFromDate] = useState('');
  const [showToDate, setToDate] = useState('');
  const [showFromTxtError, setFromTxtError] = useState('');
  const [showToTxtError, setToTxtError] = useState('');
  const [showFromDateVal, setFromDateVal] = useState('');
  const [showToDateVal, setToDateVal] = useState('');
  const fromMilli = Math.abs(Date.parse(showFromDateVal));
  const toMilli = Math.abs(Date.parse(showToDateVal));
  const currentDateMilli = Math.abs(Date.parse(new Date()));
  const [selectedFromDate, handleFromDateChange] = useState(null);
  const [selectedToDate, handleToDateChange] = useState(null);
  const [showFromDiffdays, setFromDiffdays] = useState('');
  const [showFromDateValForExport, setFromDateValForExport] = useState('');
  const [showToDateValForExport, setToDateValForExport] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('Last 1 hour');
  const metricsName = [
    'API_USAGE, API_PERFORMANCE, API_FAILED_REQ, API_THROTTLE_REQ, SUBSCRIPTION_COUNT'
  ];
  const apiList = [];
  const [apiIds, setApiIds] = useState([]);
  const [hourBased, setHourBased] = useState(true);
  const [fromDateMetrics, setFromDateMetrics] = useState(null);
  const [toDateMetrics, setToDateMetrics] = useState(null);
  const [paginationData, setPaginationData] = useState({
    totalPages:
      props?.responseHeaders['total-page-num'] > 0
        ? Math.ceil(props?.responseHeaders['total-entry-count'] / rowsPerPage)
        : 1
  });
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState([]);
  const [selectedApis, setSelectedApis] = useState([]);
  const [apiValues, setApiValues] = useState([]);
  const name = [
    { value: 'API Usage Count', key: 'API_USAGE', id: '1' },
    { value: 'API Performance', key: 'API_PERFORMANCE', id: '2' },
    { value: 'Failed Requests', key: 'API_FAILED_REQ', id: '3' },
    { value: 'Throttled-Out Requests', key: 'API_THROTTLE_REQ', id: '4' },
    { value: 'API usage by Endpoints', key: 'RESOURCE_USAGE', id: '5' },
    { value: 'API subscriptions', key: 'SUBSCRIPTION_COUNT', id: '6' }
  ];
  const [formattedData, setFormattedData] = useState([]);
  const role = 'SELLER';
  const [isResponseCodesTableDisplay, setIsResponseCodesTableDisplay] =
    useState(false);
  const [endPointsData, setEndPointsData] = useState([]);
  const [apiName, setApiName] = useState('');
  const [timePeriod, setTimePeriod] = useState('');
  const [hitsType, setHitsType] = useState('');

  const handleResponseCountsAction = (key, apiName) => {
    let endPointsData;
    setIsResponseCodesTableDisplay(true);
    props?.usageTracking?.tracking?.map((item) => {
      if (item.apiName === apiName) {
        endPointsData = item?.stats?.endPointDetails;
      }
      return true;
    });
    let timePeriod = selectedPeriod;
    if (selectedPeriod === 'Date Range' && selectedFromDate && selectedToDate) {
      timePeriod = `${selectedFromDate?.toLocaleDateString('en-GB')} to ${selectedToDate?.toLocaleDateString('en-GB')}`;
    }
    setEndPointsData(endPointsData);
    setApiName(apiName);
    setTimePeriod(timePeriod);
    setHitsType(key);
  };

  useEffect(() => {
    let toDateForStatistics = new Date();
    let oneHourTime = new Date().setHours(new Date().getHours() - 1);
    let fromDateForStatistics = new Date(oneHourTime);
    if (!props?.usageTracking?.tracking) {
      props.getApiStatistics(
        fromDateForStatistics
          .toString()
          .replace(' (India Standard Time)', '')
          .replace('+', '%2B'),
        toDateForStatistics
          .toString()
          .replace(' (India Standard Time)', '')
          .replace('+', '%2B'),
        role,
        page,
        rowsPerPage
      );
    }
  }, []);

  useEffect(() => {
    let tempData = props?.usageTracking?.tracking?.map((item) => {
      return {
        apiName: item.apiName,
        totalRequest: item?.stats?.apiSummary?.totalReq,
        chargeableHits: item?.stats?.apiSummary?.chargable,
        nonChargeableHits: item?.stats?.apiSummary?.nonChargable,
        blockedRequest: item?.stats?.apiSummary?.blockedReq,
        averageLatency: item?.stats?.apiSummary?.avgLatency,
        subscribedUsers: item?.stats?.apiSummary?.subscribedUsers
      };
    });
    setFormattedData(tempData);
    if (props.responseHeaders['total-entry-count']) {
      setPaginationData(() => ({
        totalPages: Math.ceil(
          props.responseHeaders['total-entry-count'] / rowsPerPage
        )
      }));
    }
  }, [props?.usageTracking?.tracking, props?.responseHeaders]);

  const handleChangeRowsPerPage = (event) => {
    if (event.value) {
      setRowsPerPage(event.value.value);
      setPaginationData(() => ({
        totalPages: Math.ceil(
          props?.responseHeaders['total-entry-count'] / event.value.value
        )
      }));
      props.getApiStatistics(
        showFromDateVal,
        showToDateVal,
        role,
        0,
        event.value.value
      );
    }
    setPage(0);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    props.getApiStatistics(
      showFromDateVal,
      showToDateVal,
      role,
      newPage,
      rowsPerPage
    );
  };

  const handleMetricsChange = (data) => {
    let selectedMetricsKey = data?.value.map((metrics) => metrics.key);
    let resourceUsageKey = 'RESOURCE_USAGE';
    if (selectedMetricsKey.includes(resourceUsageKey)) {
      if (selectedMetric.findIndex((item) => item.key === resourceUsageKey) >= 0) {
        const filteredVal = data.value.filter(
          (item) => item.key !== resourceUsageKey
        );
        selectedMetricsKey = filteredVal.map((metrics) => metrics.key);
        setSelectedMetric(filteredVal);
        setSelectedValues(selectedMetricsKey);
      } else {
        const filteredVal = data.value.filter(
          (item) => item.key === resourceUsageKey
        );
        selectedMetricsKey = filteredVal.map((metrics) => metrics.key);
        setSelectedMetric(filteredVal);
        setSelectedValues(selectedMetricsKey);
      }
    } else {
      selectedMetricsKey = data.value.map((metrics) => metrics.key);
      setSelectedMetric(data.value);
      setSelectedValues(selectedMetricsKey);
    }

    const getApiId = () => {
      if (selectedMetricsKey.includes(resourceUsageKey)) {
        return apiList.length > 0 ? apiList[0].apiId : '';
      }

      if (selectedMetric.findIndex((item) => item.key === resourceUsageKey) >= 0) {
        return apiIds;
      }

      return '';
    };

    loadApiMetrics(
      selectedMetricsKey,
      fromDateMetrics,
      toDateMetrics,
      'SELLER',
      getApiId()
    );
  };

  const handleApiChange = (data) => {
    let tempData = data.value.some((item) => item.key === 'All')
      ? apiValues
      : data.value;
    if (selectedApis.some((item) => item.key === 'All')) {
      tempData = data.value.some((item) => item.key === 'All')
        ? data.value.filter((item) => item.key !== 'All')
        : [];
    }
    setSelectedApis(tempData);
    const allApisList = apiValues
      .filter((item) => item.key !== 'All')
      .map((key) => key.key);
    const apiIdsList =
      data.name !== 'selectOptions' ? data.value.map((api) => api.key) : allApisList;
    loadApiMetrics(selectedValues, fromDateMetrics, toDateMetrics, role, apiIdsList);
  };

  useEffect(() => {
    setApiIds(apiList.map((api) => api.apiId).join());
  }, [apiList]);

  useEffect(() => {
    let toDateForMetrics = new Date();
    let oneHourTime = new Date().setHours(new Date().getHours() - 1);
    let fromDateForMetrics = new Date(oneHourTime);
    setFromDateMetrics(
      fromDateForMetrics
        .toString()
        .replace(' (India Standard Time)', '')
        .replace('+', '%2B')
    );
    setToDateMetrics(
      toDateForMetrics
        .toString()
        .replace(' (India Standard Time)', '')
        .replace('+', '%2B')
    );
    props
      .getProductsCount(
        idConstants.published.statusId,
        '',
        false,
        idConstants.api.categoryId
      )
      .then((response) => {
        let productCount = response.find(
          (item) => item.statusId === idConstants.published.statusId
        )?.productCount;
        props
          .getProductsByStatus(
            idConstants.published.statusId,
            '',
            0,
            productCount,
            [],
            idConstants.api.categoryId
          )
          .then((response) => {
            let newArray = response.map(({ productId, productName }, index) => ({
              value: productName,
              key: productId,
              id: (index + 2).toString()
            }));
            newArray.unshift({ value: 'Select All ', key: 'All', id: '1' });
            setApiValues(newArray);
          });
      });
    props.getApiMetrics(
      metricsName.join(),
      fromDateForMetrics
        .toString()
        .replace(' (India Standard Time)', '')
        .replace('+', '%2B'),
      toDateForMetrics
        .toString()
        .replace(' (India Standard Time)', '')
        .replace('+', '%2B'),
      role,
      ''
    );
  }, []);

  useEffect(() => {
    setToDateVal(currentDate);
    setFromDateVal(fromDate);
  }, []);

  let currentDate = new Date()
    .toString()
    .replace(' (India Standard Time)', '')
    .replace('+', '%2B');
  let diffTime = new Date().setHours(new Date().getHours() - 1);
  let fromDate = new Date(diffTime)
    .toString()
    .replace(' (India Standard Time)', '')
    .replace('+', '%2B');
  let datepickerMaxDiffDaysVal = 18497;

  const selectDateRangeForMetrics = (event) => {
    const val = event.value.value;
    let todayDate = new Date();
    let pastDay = new Date().setHours(0, 0, 0, 0);
    let pastDate = new Date(pastDay);
    let fromDateForMetrics, toDateForMetrics;
    switch (val) {
      case '1HOUR':
        toDateForMetrics = new Date();
        let oneHourTime = new Date().setHours(new Date().getHours() - 1);
        fromDateForMetrics = new Date(oneHourTime);
        setHourBased(true);
        setFromDateMetrics(
          fromDateForMetrics
            .toString()
            .replace(' (India Standard Time)', '')
            .replace('+', '%2B')
        );
        setToDateMetrics(
          toDateForMetrics
            .toString()
            .replace(' (India Standard Time)', '')
            .replace('+', '%2B')
        );
        break;
      case 'TODAY':
        fromDateForMetrics = pastDate;
        toDateForMetrics = todayDate;
        setHourBased(true);
        setFromDateMetrics(
          fromDateForMetrics
            .toString()
            .replace(' (India Standard Time)', '')
            .replace('+', '%2B')
        );
        setToDateMetrics(
          toDateForMetrics
            .toString()
            .replace(' (India Standard Time)', '')
            .replace('+', '%2B')
        );
        break;
      case '7DAYS':
        pastDate.setDate(pastDate.getDate() - 7);
        fromDateForMetrics = pastDate;
        toDateForMetrics = todayDate;
        setHourBased(false);
        setFromDateMetrics(
          fromDateForMetrics
            .toString()
            .replace(' (India Standard Time)', '')
            .replace('+', '%2B')
        );
        setToDateMetrics(
          toDateForMetrics
            .toString()
            .replace(' (India Standard Time)', '')
            .replace('+', '%2B')
        );
        break;
      case '30DAYS':
        pastDate.setDate(pastDate.getDate() - 30);
        fromDateForMetrics = pastDate;
        toDateForMetrics = todayDate;
        setHourBased(false);
        setFromDateMetrics(
          fromDateForMetrics
            .toString()
            .replace(' (India Standard Time)', '')
            .replace('+', '%2B')
        );
        setToDateMetrics(
          toDateForMetrics
            .toString()
            .replace(' (India Standard Time)', '')
            .replace('+', '%2B')
        );
        break;
      default:
        setShowText(false);
        break;
    }
    props.getApiMetrics(
      selectedValues,
      fromDateForMetrics
        .toString()
        .replace(' (India Standard Time)', '')
        .replace('+', '%2B'),
      toDateForMetrics
        .toString()
        .replace(' (India Standard Time)', '')
        .replace('+', '%2B'),
      role,
      apiIds
    );
  };

  const loadApiMetrics = (metricsStr, fromDate, toDate, role, apiIds) => {
    props.getApiMetrics(metricsStr, fromDate, toDate, role, apiIds);
  };

  const [options, setOptions] = useState([
    { label: 'Last 1 hour', value: '1HOUR', id: '1' },
    { label: 'Today', value: 'TODAY', id: '2' },
    { label: 'Yesterday', value: 'YESTERDAY', id: '3' },
    { label: 'Last 7 days', value: '7DAYS', id: '4' },
    { label: 'Last 30 days', value: '30DAYS', id: '5' },
    { label: 'Date Range', value: 'DATERANGE', id: '6' }
  ]);

  const selectDateRangeForStatistics = (event) => {
    const val = event.value.value;
    setOptions(
      options.map((option) => ({
        ...option,
        selected: option.id === event.value.id ? true : false
      }))
    );
    let todayDate = new Date();
    let pastDay = new Date().setHours(0, 0, 0, 0);
    let pastDate = new Date(pastDay);
    let fromDateForStatistics, toDateForStatistics;
    let dataRangeSelected;
    switch (val) {
      case '1HOUR':
        setShowText(false);
        setFromDate('');
        setToDate('');
        handleToDateChange(null);
        handleFromDateChange(null);
        fromDateForStatistics = fromDate;
        toDateForStatistics = currentDate;
        setSelectedPeriod('Last 1 hour');
        setFromDateValForExport('');
        setToDateValForExport('');
        break;
      case 'TODAY':
        setShowText(false);
        setFromDate('');
        setToDate('');
        handleToDateChange(null);
        handleFromDateChange(null);
        fromDateForStatistics = pastDate
          .toString()
          .replace(' (India Standard Time)', '')
          .replace('+', '%2B');
        toDateForStatistics = currentDate;
        setSelectedPeriod('Today');
        setFromDateValForExport('');
        setToDateValForExport('');
        break;
      case 'YESTERDAY':
        setShowText(false);
        setFromDate('');
        setToDate('');
        handleToDateChange(null);
        handleFromDateChange(null);
        pastDate.setDate(todayDate.getDate() - 1);
        fromDateForStatistics = pastDate
          .toString()
          .replace(' (India Standard Time)', '')
          .replace('+', '%2B');
        let endYestDate = todayDate.setHours(23, 59, 59, 999);
        let yestDate = new Date(endYestDate);
        yestDate.setDate(todayDate.getDate() - 1);
        toDateForStatistics = yestDate
          .toString()
          .replace(' (India Standard Time)', '')
          .replace('+', '%2B');
        setSelectedPeriod('Yesterday');
        setFromDateValForExport('');
        setToDateValForExport('');
        break;
      case '7DAYS':
        setShowText(false);
        handleToDateChange(null);
        handleFromDateChange(null);
        pastDate.setDate(pastDate.getDate() - 7);
        fromDateForStatistics = pastDate
          .toString()
          .replace(' (India Standard Time)', '')
          .replace('+', '%2B');
        toDateForStatistics = currentDate;
        setSelectedPeriod('Last 7 Days');
        setFromDateValForExport('');
        setToDateValForExport('');
        break;
      case '30DAYS':
        setShowText(false);
        handleToDateChange(null);
        handleFromDateChange(null);
        pastDate.setDate(pastDate.getDate() - 30);
        fromDateForStatistics = pastDate
          .toString()
          .replace(' (India Standard Time)', '')
          .replace('+', '%2B');
        toDateForStatistics = currentDate;
        setSelectedPeriod('Last 30 Days');
        setFromDateValForExport('');
        setToDateValForExport('');
        break;
      case 'DATERANGE':
        dataRangeSelected = true;
        setShowText(true);
        setFromTxtError('');
        setToTxtError('');
        break;
      default:
        setShowText(false);
        break;
    }
    if (!dataRangeSelected) {
      setFromDateVal(fromDateForStatistics);
      setToDateVal(toDateForStatistics);
      props.getApiStatistics(
        fromDateForStatistics,
        toDateForStatistics,
        role,
        page,
        rowsPerPage
      );
    }
  };

  const enableFromdate = (event) => {
    handleFromDateChange(event);
    const fromDateSelected = event;
    setFromDate(event);
    const date = new Date();
    let selectedDate = new Date(fromDateSelected);
    selectedDate.setHours(0, 0, 0, 0);
    let fromDateFinal = selectedDate
      .toString()
      .toString()
      .replace(' (India Standard Time)', '')
      .replace('+', '%2B');
    setFromDateVal(fromDateFinal);
    setFromDateValForExport(selectedDate);
    const diffTimeCalculated = Math.abs(Date.parse(date) - Date.parse(selectedDate));
    const diffDays = Math.ceil(diffTimeCalculated / (1000 * 60 * 60 * 24));
    setFromDiffdays(diffDays);
    if (showToDate !== '') {
      if (fromDateSelected > showToDate || fromDateSelected === showToDate) {
        setFromTxtError(
          i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.FROM_DATE_IS_NOT_VALID')
        );
        setToTxtError('');
      } else if (selectedDate < date) {
        setFromTxtError('');
        setToTxtError('');
      }
      if (selectedDate < date) {
        if (diffDays > 365) {
          setFromTxtError(
            i18next.t(
              'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.DATE_CANNOT_BE_BEYOND_365_DAYS'
            )
          );
        }
      } else if (showToDate !== '' && fromDateSelected === '') {
        setFromTxtError('');
        setToTxtError('');
      } else if (
        (selectedDate > date && showToDate === '') ||
        (selectedDate > date && showToDate !== '')
      ) {
        setFromTxtError(
          i18next.t(
            'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.FROM_DATE_CANNOT_BE_FUTURE_DATE'
          )
        );
        setToTxtError('');
      } else if (
        (fromDateSelected.toString() === 'Invalid Date' && showToDate === ' ') ||
        (fromDateSelected.toString() === 'Invalid Date' && showToDate !== ' ')
      ) {
        setFromTxtError('');
        setToTxtError('');
      } else {
        setFromTxtError('');
        setToTxtError('');
      }
    } else if (fromMilli > toMilli && showToDateVal === '') {
      setToTxtError('');
      setFromTxtError('');
    } else if (fromMilli < toMilli && showToDateVal === '') {
      setToTxtError(
        i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.TO_DATE_IS_NOT_VALID')
      );
      setFromTxtError('');
    } else if (
      (selectedDate > date && showToDate === '') ||
      (selectedDate > date && showToDate !== '')
    ) {
      setFromTxtError(
        i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.FROM_DATE_CANNOT_BE_FUTURE_DATE'
        )
      );
    } else if (diffDays === datepickerMaxDiffDaysVal && showToDate === '') {
      setFromTxtError(
        i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.FROM_DATE_IS_NOT_VALID')
      );
      setToTxtError('');
    } else if (diffDays > 365 || (diffDays > 365 && showToDate !== '')) {
      setFromTxtError(
        i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.DATE_CANNOT_BE_BEYOND_365_DAYS')
      );
      setToTxtError('');
    } else {
      setFromTxtError('');
    }
  };

  const enableTodate = (event) => {
    handleToDateChange(event);
    const toDate = event;
    setToDate(event);
    let selectedDate = new Date(toDate);
    selectedDate.setHours(23, 59, 59, 999);
    let toDateFinal = selectedDate
      .toString()
      .replace(' (India Standard Time)', '')
      .replace('+', '%2B');
    setToDateVal(toDateFinal);
    setToDateValForExport(selectedDate);
    const diffTimeCalculated = Math.abs(
      Date.parse(toDate) - Date.parse(showFromDate)
    );
    const diffDays = Math.ceil(diffTimeCalculated / (1000 * 60 * 60 * 24));
    if (showFromDate !== '' && toDate !== '') {
      if (
        showFromDate > currentDateMilli ||
        (fromMilli > currentDateMilli && !isEmpty(toMilli)) ||
        (fromMilli > currentDateMilli && toMilli > currentDateMilli)
      ) {
        setToTxtError('');
        setFromTxtError('From Date cannot be future date');
      } else if (
        toDate < showFromDate ||
        (toDate < showFromDate && showFromDate < currentDateMilli)
      ) {
        setToTxtError('To Date is not valid');
        setFromTxtError('');
      } else if (diffDays <= 365) {
        if (showFromDiffdays > 365 && diffDays >= 0) {
          setFromTxtError('Date cannot be beyond 365 Days');
          setToTxtError('');
        } else {
          setFromTxtError('');
          setToTxtError('');
        }
      } else if (showFromDiffdays > 365 && toDate !== '') {
        setFromTxtError('Date cannot be beyond 365 Days');
        setToTxtError('');
      } else if (
        (toDate.toString() === 'Invalid Date' && showFromDate === ' ') ||
        (toDate.toString() === 'Invalid Date' && showFromDate !== ' ') ||
        (showFromDate.toString() === 'Invalid Date' && toDate === ' ') ||
        (showFromDate.toString() === 'Invalid Date' && toDate !== ' ')
      ) {
        setFromTxtError('');
        setToTxtError('');
      } else {
        setToTxtError('');
        setFromTxtError('');
      }
    }
  };

  let trackingdata;
  let dataForExcelandPDF = [];

  if (props.usageTracking.tracking) {
    trackingdata = props.usageTracking.tracking;
    trackingdata?.forEach((data) => {
      dataForExcelandPDF.push({
        apiName: data.apiName,
        totalRequest: data?.stats?.apiSummary?.totalReq,
        chargeableHits: data?.stats?.apiSummary?.chargable,
        nonChargeableHits: data?.stats?.apiSummary?.nonChargable,
        blockedRequest: data?.stats?.apiSummary?.blockedReq,
        averageLatency: data?.stats?.apiSummary?.avgLatency + 'ms',
        subscribedUsers: data?.stats?.apiSummary?.subscribedUsers
      });
    });
  }

  const loadStatisticsData = () => {
    setPage(0);
    setRowsPerPage(10);
    setSelectedPeriod('Date Range');
    props.getApiStatistics(showFromDateVal, showToDateVal, role, page, rowsPerPage);
  };

  const timePeriodBlock = () => {
    return (
      <>
        <div className='flex-calender'>
          <div className='date-form'>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <label className='margin-bottom-8'>
                  {i18next.t('ANALYTICS_AND_TRACKING.FROM_DATE')}
                </label>
                <Box>
                  <DatePicker
                    name='date'
                    size='small'
                    autoOk
                    className='usage-tracking-datefield'
                    placeholder='dd/mm/yyyy'
                    variant='outlined'
                    inputVariant='outlined'
                    invalidDateMessage=''
                    format='dd/MM/yyyy'
                    value={selectedFromDate}
                    minDateMessage=''
                    InputAdornmentProps={{ position: 'end' }}
                    maxDateMessage=''
                    onChange={enableFromdate}
                  />
                  <FormHelperText sx={{ color: 'red' }}>
                    {showFromTxtError}
                  </FormHelperText>
                </Box>
              </LocalizationProvider>
            </FormControl>
          </div>
          <div className='date-form'>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <label className='margin-bottom-8'>
                  {i18next.t('ANALYTICS_AND_TRACKING.TO_DATE')}
                </label>
                <Box>
                  <DatePicker
                    name='date'
                    size='small'
                    autoOk
                    className='usage-tracking-datefield'
                    placeholder='dd/mm/yyyy'
                    invalidDateMessage=''
                    variant='outlined'
                    inputVariant='outlined'
                    maxDateMessage=''
                    format='dd/MM/yyyy'
                    value={selectedToDate}
                    minDateMessage=''
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={enableTodate}
                  />
                  <FormHelperText sx={{ color: 'red' }}>
                    {' '}
                    {showToTxtError}
                  </FormHelperText>
                </Box>
              </LocalizationProvider>
            </FormControl>
          </div>
        </div>
        <div
          className={`${
            !showText || (selectedToDate && selectedFromDate)
              ? 'search-btn export-pdf'
              : 'searching'
          }`}
          onClick={() => loadStatisticsData()}
        >
          <i className='simple-menu boschicon-bosch-ic-search -size-2xl search-icon'></i>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      {props.loader.loader && <Loader />}
      {!isResponseCodesTableDisplay && (
        <>
          <div className='api-statistics'>
            <div className='breadcrumb-statistics'>
              <h5 className='heading-tag highlight -size-l'>
                {i18next.t('DYNAMIC_FORM.API_STATISTICS.API_STATISTICS')}
              </h5>
            </div>
            <hr className='statistics-line' />
            <div className='statistic-header'>
              <div className='statistic-header_style'>
                <div className='statistic-header_style_content'>
                  <div className='statistic-header_style_content_select'>
                    <div className='dropdown-field'>
                      <Dropdown
                        options={options}
                        displayName={i18next.t(
                          'DYNAMIC_FORM.API_STATISTICS.SELECT_PERIOD'
                        )}
                        name='period'
                        handleChange={(event) => {
                          selectDateRangeForStatistics(event);
                        }}
                      />
                    </div>
                  </div>
                  <div className='d-sm-none datefield-alignment'>
                    {showText && timePeriodBlock()}
                  </div>
                </div>

                <div
                  className={`menu-icon ${props?.errorMsg ? 'disable-click' : ''}`}
                >
                  <div className='menu-xlv'>
                    <GenerateExcel
                      getApiStatistics={props.getApiStatistics}
                      dataForExcel={dataForExcelandPDF}
                      role={role}
                      showToDateVal={showToDateVal}
                      showFromDateVal={showFromDateVal}
                    />
                  </div>
                  <GeneratePDF
                    getApiStatistics={props.getApiStatistics}
                    dataForPDF={dataForExcelandPDF}
                    selFromDate={showFromDateValForExport.toLocaleString()}
                    selToDate={showToDateValForExport.toLocaleString()}
                    selectedPeriod={selectedPeriod}
                    companyDetails={props.companyDetails}
                    countryName={props.countryName}
                    role={role}
                    showToDateVal={showToDateVal}
                    showFromDateVal={showFromDateVal}
                    {...props}
                  />
                </div>
              </div>
              <div className='d-lg-none datefield-alignment'>
                {showText && timePeriodBlock()}
              </div>
            </div>
            {props.loader.loader ? (
              <Loader />
            ) : (
              <div className='table-header api-static-table'>
                <Table
                  centerAlignClassName='field-center-align'
                  rowData={apiStatisticsHeadings}
                  data={formattedData}
                  responseCountsAction={handleResponseCountsAction}
                  handlePerPage={handleChangeRowsPerPage}
                  handlePagination={handleChangePage}
                  currentPage={page}
                  rowsPerPage={rowsPerPage}
                  paginationData={paginationData}
                  noDataAvailable={props?.errorMsg}
                />
              </div>
            )}
            <div className='breadcrumb-grid'>
              <h5 className='heading-tag text -size-l margin-bottom__1'>
                {i18next.t('DYNAMIC_FORM.API_STATISTICS.API_METRICS')}
              </h5>
              <h5 className='heading-tag text -size-l'>
                {i18next.t('DYNAMIC_FORM.API_STATISTICS.CHOOSE_THE_METRIC_TYPE')}
              </h5>
            </div>
            <div className='multiple-select'>
              <div className='multiple-select_field'>
                <MultiSelect
                  options={name}
                  displayName={i18next.t('DYNAMIC_FORM.API_STATISTICS.METRICS')}
                  classname='custom-class'
                  disabled={false}
                  values={selectedMetric}
                  handleChange={handleMetricsChange}
                  error=''
                  name='selectOptions'
                  placeHolder={i18next.t(
                    'DYNAMIC_FORM.API_STATISTICS.SELECT_OPTIONS'
                  )}
                />
              </div>
              <div className='multiple-select_field'>
                <MultiSelect
                  options={apiValues}
                  displayName={i18next.t('DYNAMIC_FORM.NAVIGATION_OPTIONS.API')}
                  classname='custom-class'
                  disabled={false}
                  values={selectedApis}
                  handleChange={handleApiChange}
                  error=''
                  name='selectOptions'
                  placeHolder={i18next.t('DYNAMIC_FORM.API_STATISTICS.SEARCH')}
                />
              </div>
              <div className='multiple-select_field'>
                <Dropdown
                  options={[
                    { label: 'Last 1 hour', value: '1HOUR', id: '1' },
                    { label: 'Today', value: 'TODAY', id: '2' },
                    { label: 'Last 7 days', value: '7DAYS', id: '4' },
                    { label: 'Last 30 days', value: '30DAYS', id: '5' }
                  ]}
                  placeHolder={i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.SELECT')}
                  displayName={i18next.t(
                    'DYNAMIC_FORM.API_STATISTICS.SELECT_PERIOD'
                  )}
                  name='period'
                  handleChange={(event) => {
                    selectDateRangeForMetrics(event);
                  }}
                />
              </div>
            </div>
            <MetricsGraph
              selectedValues={selectedValues}
              hourBased={hourBased}
              selectedUsageByEndpoints={false}
              {...props}
            />
          </div>
          <Footer customClassName='d-lg-none' />
        </>
      )}
      {isResponseCodesTableDisplay && (
        <div>
          <APIHitsBreakDown
            endPointsData={endPointsData}
            apiName={apiName}
            selectedPeriod={selectedPeriod}
            timePeriod={timePeriod}
            hitsType={hitsType}
            setIsResponseCodesTableDisplay={setIsResponseCodesTableDisplay}
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default ApiStatistics;
